import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";

export default function StepButtons(props: {
    activeStep: number, steps: string[],
    handleNewMeal: () => void;
    handleBack: () => void;
    handleNext: () => void;
    saveMeals: () => void;
}) {

    const {t} = useTranslation();

    return (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            {props.activeStep !== 0 && (
                <Button onClick={props.handleBack} sx={{ mt: 3, ml: 1 }}>
                    {t("Back")}
                </Button>
            )}
            {(props.activeStep != props.steps.length - 1) ?
                <>
                    <Button variant="contained" sx={{ mt: 3, ml: 1 }} onClick={props.handleNewMeal}>{t("New meal")}</Button>
                    <Button
                        variant="contained"
                        onClick={props.handleNext}
                        sx={{ mt: 3, ml: 1 }}
                    >
                        {t("Next")}
                    </Button></>
                : <Button
                    variant="contained" sx={{ mt: 3, ml: 1 }}
                    onClick={props.saveMeals}>
                    {t("Save")}
                </Button>
            }
        </Box>
    );
}