import { Grid, Typography } from "@mui/material"

export default function Value(props: {
    label: string, value: string, indicator?: React.ReactNode
}) {
    return (
        <Grid container spacing={2}>
            <Grid item>
                {props.label}
            </Grid>
            <Grid item>
                {props.value}
                {props.indicator ? props.indicator : <></>}
            </Grid>
        </Grid>
    )
}