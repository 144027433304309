import { Grid } from "@mui/material";
import Planner from "./planner/Planner";
import CalculationStepper from "../CalculationStepper";
import RecipeBrowser from "../../components/RecipeBrowser";
import { useState } from "react";
import { Copyright } from "../../Copyright";
import { Recipe } from "../../model/Recipe";

export function Main() {
    const [date, setDate] = useState<Date>(new Date());
    const [recipeToBeApplied, setRecipeToBeApplied] = useState<Recipe | undefined>();

    return (
        <>
            <Grid container spacing={2}>
                <Grid item md={4} xs={12}><Planner date={date} onDateChange={newValue => setDate(newValue ?? new Date())} /></Grid>
                <Grid item md={4} xs={12}><CalculationStepper date={date} recipeToBeApplied={recipeToBeApplied}
                    setRecipeToBeApplied={setRecipeToBeApplied} /></Grid>
                <Grid item md={4} xs={0}><RecipeBrowser applyRecipe={recipe => setRecipeToBeApplied(recipe)} /></Grid>
            </Grid>
            <Copyright />
        </>
    )
} 