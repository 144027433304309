import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './i18n';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from 'react-oidc-context';
import { User, WebStorageStateStore } from 'oidc-client-ts';

import { Navigation } from './components/Navigation';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const onSigninCallback = (_user: User | void): void => {
  window.history.replaceState(
    {},
    document.title,
    window.location.pathname
  )
}

const oidcConfig = {
  authority: "https://auth.siof.pl/realms/MealCalculator",
  client_id: "ui-client",
    redirect_uri: "http://meal-calculator.siof.pl/",
  // for local development:
  // redirect_uri: "http://localhost:3000",
  // for Android: 
  // redirect_uri: "intent://app.meal-calculator.siof.pl/",
  onSigninCallback: onSigninCallback
  // userStore: new WebStorageStateStore({ store: window.localStorage }),
};

root.render(
  <React.StrictMode>
    <AuthProvider {...oidcConfig}>
      <App/>
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
