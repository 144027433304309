import { Meal } from "../../model/Meal";
import { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import Value from "./Value";

export default function DailySummary(props: { meals: Meal[] }) {


    function sumIfPresent(additionalValue: number | undefined, unitValue: number, currentValue: number, callmethod: React.Dispatch<React.SetStateAction<number>>) {
        if (additionalValue) {
            currentValue += additionalValue / 100 * unitValue;
        }

        return currentValue;
    }

    useEffect(() => {
        var newCarbohydrates = 0;
        var newProteins = 0;
        var newFats = 0;
        var newKcal = 0;

        props.meals.map(meal => {
            return meal.ingredients;
        })
        
        .map(ingredient => {
            // newKcal = sumIfPresent(ingredient.kcal, ingredient.unitValue, newKcal, setKcal)
            // newCarbohydrates = sumIfPresent(ingredient.carbohydrates, ingredient.unitValue, newCarbohydrates, setCarbohydrates)
            // newProteins = sumIfPresent(ingredient.proteins, ingredient.unitValue, newProteins, setProteins)
            // newFats = sumIfPresent(ingredient.fats, ingredient.unitValue, newFats, setFats)
        })
        setKcal(newKcal)
        setCarbohydrates(newCarbohydrates);
        setProteins(newProteins)
        setFats(newFats)

    }, [props])

    const [carbohydrates, setCarbohydrates] = useState(0);
    const [proteins, setProteins] = useState(0);
    const [fats, setFats] = useState(0);
    const [kcal, setKcal] = useState(0);

    return (
        <>
            <Stack>
                <Value label="Kcal" value={kcal.toPrecision(5)} />
                <Value label="Carbohydrates" value={carbohydrates.toPrecision(3) + "g"} />
                <Value label="Proteins" value={proteins.toPrecision(3) + "g"} />
                <Value label="Fats" value={fats.toPrecision(3) + "g"} />
            </Stack>
        </>
    )
}