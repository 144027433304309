import { Button, CircularProgress, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { ApiService } from "../../services/ApiService";
import { Ingredient } from "../../model/Ingredient";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";


export default function ListIngredients() {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const [ingredients, setIngredients] = useState<Ingredient[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        ApiService.getIngredients()
            .then(response => {
                setIngredients(response.data);
            })
            .finally(() => {
                setLoading(false);
            })
    }, [])

    function handleOpenIngredientCreation(): any {
        navigate('/add_ingredient');
    }

    const progress = loading ? <CircularProgress sx={{ my: { md: 10 } }} /> : <></>;

    return (
        <Stack spacing={2}>
            Ingredients
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Ingredient</TableCell>
                            <TableCell align="right">Calories in 100g</TableCell>
                            <TableCell align="right">Fats in 100g&nbsp;(g)</TableCell>
                            <TableCell align="right">Carbs in 100g&nbsp;(g)</TableCell>
                            <TableCell align="right">Proteins in 100g&nbsp;(g)</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {ingredients.map((ingredient) => (
                            <TableRow
                                key={ingredient.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    <a href={"/update_ingredient/" + ingredient.id}>{t(ingredient.name)}</a>
                                </TableCell>
                                <TableCell align="right">{ingredient.kcal}</TableCell>
                                <TableCell align="right">{ingredient.fats}</TableCell>
                                <TableCell align="right">{ingredient.carbohydrates}</TableCell>
                                <TableCell align="right">{ingredient.proteins}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {progress}
            </TableContainer>
            <Button variant="contained" sx={{ mt: 3, ml: 1, mb: 3 }} onClick={handleOpenIngredientCreation}>{t("Add")}</Button>
        </Stack>)
}