import { Button, Paper, Snackbar, SnackbarCloseReason, Stack, TextField, Typography } from "@mui/material";
import { ApiService } from "../../services/ApiService";
import { Ingredient } from "../../model/Ingredient";
import { useState } from "react";
import { useAuth } from "react-oidc-context";
import { useTranslation } from "react-i18next";


export default function AddIngredient() {

    const auth = useAuth();
    const [t] = useTranslation();

    const [name, setName] = useState<string>();
    const [unitValue, setUnitValue] = useState<number>();
    const [defaultUnitValue, setDefaultUnitValue] = useState<number>();
    const [glycemicIndex, setGlycemicIndex] = useState<number>();
    const [carbohydrates, setCarbohydrates] = useState<number>();
    const [proteins, setProteins] = useState<number>();
    const [fats, setFats] = useState<number>();
    const [kcal, setKcal] = useState<number>();

    const [snackBarOpen, setSnackBarOpen] = useState<boolean>(false);
    const [snackBarMessage, setSnackBarMessage] = useState<string>("");

    function save() {
        ApiService.addIngredient({
            name: name,
            unitValue: 100,
            defaultUnitValue: 100,
            unit: 'g',
            glycemicIndex: 1,
            carbohydrates: carbohydrates,
            proteins: proteins,
            fats: fats,
            kcal: kcal
        } as Ingredient, auth)
            .then(() => {
                setSnackBarMessage("Ingredient added");
                setSnackBarOpen(true);
            })
            .catch(() => {
                setSnackBarMessage("Ingredient creation error");
                setSnackBarOpen(true);
            });
    }

    const handleCloseSnackbar = (
        event: React.SyntheticEvent | Event,
        reason?: SnackbarCloseReason,
    ) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackBarOpen(false);
    };

    return (
        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
            <Stack spacing={2}>
                <Typography variant="h6" >
                    {t("Add ingredient")}
                </Typography>
                <TextField
                    required
                    id="name"
                    name="name"
                    label="Name"
                    fullWidth
                    variant="standard"
                    onChange={(event: any) => {
                        setName(event.target.value)
                    }}
                    value={name}
                />
                <TextField
                    id="kcal"
                    name="kcal"
                    label="kcal"
                    fullWidth
                    variant="standard"
                    onChange={(event: any) => {
                        setKcal(event.target.value)
                    }}
                    value={kcal}
                />
                <TextField
                    id="carbohydrates"
                    name="carbohydrates"
                    label="carbohydrates"
                    fullWidth
                    variant="standard"
                    onChange={(event: any) => {
                        setCarbohydrates(event.target.value)
                    }}
                    value={carbohydrates}
                />
                <TextField
                    id="proteins"
                    name="proteins"
                    label="proteins"
                    fullWidth
                    variant="standard"
                    onChange={(event: any) => {
                        setProteins(event.target.value)
                    }}
                    value={proteins}
                />
                <TextField
                    id="fats"
                    name="fats"
                    label="fats"
                    fullWidth
                    variant="standard"
                    onChange={(event: any) => {
                        // props.onChangeSelectedIngredientsUnitValue(props.index, event.target.value)
                    }}
                    value={fats}
                />
                <Button variant="contained" sx={{ mt: 3, ml: 1 }} onClick={save}>Add</Button>
            </Stack>
            <Snackbar
                open={snackBarOpen}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
                message={snackBarMessage}
            />
        </Paper>
    )
}