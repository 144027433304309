import { AppBar, Box, IconButton, Menu, MenuItem, Toolbar, Typography } from "@mui/material"
import MenuIcon from '@mui/icons-material/Menu';
import React from "react"
import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";
import { useNavigate } from "react-router-dom";

export function Navigation() {

  interface Page { name: string, uri: string, role?: string }

  const auth = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

  const pages: Page[] = [
    { name: "Main Page", uri: "/" },
    { name: "Ingredients", uri: "/ingredients" },
    { name: "Recipes", uri: "/recipes" },
    { name: "Shopping list", uri: "/shopping_list" },
    { name: "Categories", uri: "/categories" },
  ]

  function handleRedirect(event: any, destination: string): void {
    navigate(destination);
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  return (
    <AppBar
      position="static"
      color="primary"
      elevation={0}
      sx={{
        position: 'relative',
        borderBottom: (t) => `1px solid ${t.palette.divider}`,
      }}
    >
      <Toolbar>
        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{ display: { xs: 'block', md: 'none' } }}
          >
            {pages.map((page) => (
              <MenuItem
                onClick={(event) => handleRedirect(event, page.uri)}
              >
                <Typography sx={{ textAlign: 'center' }}>{t(page.name)}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>

        <Typography variant="h6" color="inherit" noWrap>
        </Typography>
        Plan your meals {auth.user?.profile.preferred_username}

        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
          {pages.map(page => {
            return <MenuItem
              onClick={(event) => handleRedirect(event, page.uri)}
            >
              <Typography sx={{ textAlign: 'center' }}>{t(page.name)}</Typography>
            </MenuItem>
          })}
        </Box>

      </Toolbar>
    </AppBar>
  )
}
