import { Button, Snackbar, SnackbarCloseReason, Stack, TextField } from "@mui/material";
import { ApiService } from "../../services/ApiService";
import { Ingredient } from "../../model/Ingredient";
import { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { useParams } from "react-router-dom";


export default function UpdateIngredient() {

    const auth = useAuth();
    const params = useParams()
    const ingredientId = params.id

    const [snackBarOpen, setSnackBarOpen] = useState<boolean>(false);
    const [snackBarMessage, setSnackBarMessage] = useState<string>("");

    const [name, setName] = useState<string>();
    const [unit, setUnit] = useState<string>();
    const [defaultUnitValue, setDefaultUnitValue] = useState<number>();
    const [glycemicIndex, setGlycemicIndex] = useState<number>();
    const [carbohydrates, setCarbohydrates] = useState<number>();
    const [proteins, setProteins] = useState<number>();
    const [fats, setFats] = useState<number>();
    const [kcal, setKcal] = useState<number>();

    useEffect(() => {
        if (ingredientId) {
            ApiService.getIngredient(ingredientId as unknown as number).then(response => {
                setName(response.data.name)
                setUnit(response.data.unit)
                setKcal(response.data.kcal)
                setCarbohydrates(response.data.carbohydrates)
                setProteins(response.data.proteins)
                setFats(response.data.fats)
            })
        }
    }, [ingredientId])


    function update() {
        ApiService.updateIngredient({
            id: ingredientId as unknown as number,
            name: name,
            defaultUnitValue: defaultUnitValue,
            unit: unit,
            glycemicIndex: glycemicIndex,
            carbohydrates: carbohydrates,
            proteins: proteins,
            fats: fats,
            kcal: kcal
        } as Ingredient, auth)
            .then(() => {
                setSnackBarMessage("Ingredient updated");
                setSnackBarOpen(true);
            })
            .catch(() => {
                setSnackBarMessage("Ingredient update error");
                setSnackBarOpen(true);
            })
    }

    function remove() {
        ApiService.removeIngredient(ingredientId as unknown as number);
    }

    const handleCloseSnackbar = (
        event: React.SyntheticEvent | Event,
        reason?: SnackbarCloseReason,
    ) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackBarOpen(false);
    };

    return (
        <Stack spacing={2}>
            Add ingredients
            <TextField
                required
                id="name"
                name="name"
                placeholder="Name"
                fullWidth
                variant="standard"
                onChange={(event: any) => {
                    setName(event.target.value)
                }}
                value={name}
            />
            <TextField
                required
                id="unit"
                name="unit"
                placeholder="Unit"
                fullWidth
                variant="standard"
                onChange={(event: any) => {
                    setUnit(event.target.value)
                }}
                value={unit}
            />
            <TextField
                required
                id="kcal"
                name="kcal"
                placeholder="kcal"
                fullWidth
                variant="standard"
                onChange={(event: any) => {
                    setKcal(event.target.value)
                }}
                value={kcal}
            />
            <TextField
                required
                id="carbohydrates"
                name="carbohydrates"
                placeholder="carbohydrates"
                fullWidth
                variant="standard"
                onChange={(event: any) => {
                    setCarbohydrates(event.target.value)
                }}
                value={carbohydrates}
            />
            <TextField
                required
                id="proteins"
                name="proteins"
                placeholder="proteins"
                fullWidth
                variant="standard"
                onChange={(event: any) => {
                    setProteins(event.target.value)
                }}
                value={proteins}
            />
            <TextField
                required
                id="fats"
                name="fats"
                placeholder="fats"
                fullWidth
                variant="standard"
                onChange={(event: any) => {
                    setFats(event.target.value)
                }}
                value={fats}
            />
            <Button variant="contained" sx={{ mt: 3, ml: 1 }} onClick={update}>Update</Button>
            <Button variant="contained" sx={{ mt: 3, ml: 1 }} onClick={remove}>Remove</Button>
            <Snackbar
                open={snackBarOpen}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
                message={snackBarMessage}
            />
        </Stack>)
}