import { Button, CircularProgress, List, ListItem, ListItemButton, ListItemText, Paper } from "@mui/material";
import { ApiService } from "../services/ApiService";
import { useAuth } from "react-oidc-context";
import React, { useEffect, useState } from "react";
import { Recipe } from "../model/Recipe";
import RecipeLine from "../pages/meal_planner/recipes/RecipeLine";
import { useNavigate } from "react-router-dom";
import { Category } from "../model/Category";
import { useTranslation } from "react-i18next";

export default function RecipeBrowser(props: { applyRecipe?: (recipe: Recipe) => void }) {

    const auth = useAuth();
    const navigate = useNavigate();
    const {t} = useTranslation();

    const [recipes, setRecipes] = useState<Recipe[]>([]);
    const [categories, setCategories] = useState<Category[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<Category>();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (selectedCategory) {
            setLoading(true);
            ApiService.getRecipesOfCategory(auth, selectedCategory.id, 0)
                .then((response) => {
                    setRecipes(response.data)
                })
                .catch()
                .finally(() => {
                    setLoading(false);
                })
        }
    }, [selectedCategory])

    useEffect(() => {
        setLoading(true);
        ApiService.getCategories(auth).then(response => {
            setCategories(response.data);
        })
            .catch()
            .finally(() => {
                setLoading(false);
            })
    }, []);

    function handleOpenRecipeCreation(): any {
        navigate('/add_recipe');
    }

    const categoriesSection =
        <>
            {categories.map(category => {
                return <ListItem>
                    <ListItemButton key={category.id} component="a">
                        <ListItemText primary={category.name} onClick={() => { setSelectedCategory(category) }} />
                    </ListItemButton>
                </ListItem>
            })}
        </>

    const progress = loading ? <CircularProgress sx={{ my: { md: 10 } }} /> : <></>;

    return (
        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
            {categoriesSection}
            {progress}
            {recipes.map(recipe => {
                return (
                    <List>
                        <RecipeLine recipe={recipe} key={recipe.name} onApplyRecipe={props.applyRecipe} />
                    </List>
                )
            })}
            <Button variant="contained" sx={{ mt: 3, ml: 1 }} onClick={handleOpenRecipeCreation}>{t("Add")}</Button>
        </Paper>
    );
}