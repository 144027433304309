import { Stack, Tooltip } from "@mui/material";
import { Ingredient } from "../../model/Ingredient";
import React, { useEffect, useState } from "react";
import { GlycemicLoad } from "../../model/GlycemicLoad";
import Value from "./Value";

export default function MealSummary(props: { selectedIngredients: Ingredient[] }) {

    function sumIfPresent(additionalValue: number | undefined, defaultUnitValue: number, unitValue: number, currentValue: number, callmethod: React.Dispatch<React.SetStateAction<number>>) {
        if (additionalValue) {
            currentValue += (additionalValue / defaultUnitValue) * unitValue;
        }

        return currentValue;
    }

    useEffect(() => {
        var newgl = 0;
        var newCarbohydrates = 0;
        var newProteins = 0;
        var newFats = 0;
        var newKcal = 0;

        props.selectedIngredients.map(ingredient => {
            newgl += ingredient.glycemicIndex * (ingredient.carbohydrates / ingredient.defaultUnitValue * ingredient.unitValue) / 100;
            newKcal = sumIfPresent(ingredient.kcal, ingredient.defaultUnitValue, ingredient.unitValue, newKcal, setKcal)
            newCarbohydrates = sumIfPresent(ingredient.carbohydrates, ingredient.defaultUnitValue, ingredient.unitValue, newCarbohydrates, setCarbohydrates)
            newProteins = sumIfPresent(ingredient.proteins, ingredient.defaultUnitValue, ingredient.unitValue, newProteins, setProteins)
            newFats = sumIfPresent(ingredient.fats, ingredient.defaultUnitValue, ingredient.unitValue, newFats, setFats)
        })
        setGl(newgl);
        setKcal(newKcal)
        setCarbohydrates(newCarbohydrates);
        setProteins(newProteins)
        setFats(newFats)

        if (newgl > 10) {
            if (newgl > 20) {
                setGlRating(GlycemicLoad.HIGH);
            } else {
                setGlRating(GlycemicLoad.MEDIUM);
            }
        } else {
            setGlRating(GlycemicLoad.LOW);
        }
    }, [props])

    const [gl, setGl] = useState(0);
    const [glRating, setGlRating] = useState(GlycemicLoad.LOW);
    const [carbohydrates, setCarbohydrates] = useState(0);
    const [proteins, setProteins] = useState(0);
    const [fats, setFats] = useState(0);
    const [kcal, setKcal] = useState(0);

    return (
        <Stack>
            <Value label="Kcal" value={kcal.toPrecision(5)}/>
            <Value label="Carbohydrates" value={carbohydrates.toPrecision(3) + " g"}/>
            <Value label="Proteins" value={proteins.toPrecision(3) + " g"}/>
            <Value label="Fats" value={fats.toPrecision(3) + " g"}/>
            <Value label="Glycemic Load" value={gl.toPrecision(3)} indicator={glRating.getIcon()}/>
        </Stack>
    )
}