import React from "react";
import { Ingredient } from "../../model/Ingredient";
import { Autocomplete, Grid, TextField, Tooltip } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from "react-i18next";


interface IngredientSelectionInfo {
    id: number;
    name: string;
    unitValue: number;
    unit: string;
}

export default function IngredientLine(props: {
    ingredient: IngredientSelectionInfo, index: number,
    availableIngredientsKeys: string[],
    onChangeSelectedIngredients: (index: number, newValue: string) => void,
    onChangeSelectedIngredientsUnitValue: (index: number, newValue: number) => void,
    onRemoveIngredient: (index: number) => void
}) {

    const { t } = useTranslation();

    return (
        <React.Fragment>
            <Grid item xs={12} sm={6}>
                <Autocomplete
                    onChange={(event: any, newValue: string | null) => {
                        if (newValue) {
                            props.onChangeSelectedIngredients(props.index, newValue);
                        }
                    }
                    }
                    disablePortal
                    getOptionLabel={(option) => t(option)}
                    data-testid={"ingredient-combo-box-" + props.index}
                    value={props.ingredient.name}
                    options={props.availableIngredientsKeys}
                    // sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="Ingredient" />}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <TextField
                    required
                    id="unitValue"
                    name="unitValue"
                    label={`Amount [${props.ingredient.unit}]`}
                    fullWidth
                    variant="standard"
                    onChange={(event: any) => {
                        props.onChangeSelectedIngredientsUnitValue(props.index, event.target.value)
                    }}
                    value={props.ingredient.unitValue}
                />
            </Grid>
            <Grid item xs={12} sm={1}>
                <Tooltip title={t('Remove this ingredient from your meal')}>
                    <DeleteIcon onClick={() => props.onRemoveIngredient(props.index)} sx={{ mt: 1.5 }} />
                </Tooltip>
            </Grid>
        </React.Fragment>
    )
}
