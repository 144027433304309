export function formatToDateOnly(date: Date) {
  return date.toISOString().split('T')[0];
}

export function ingredientWithUnitValue(ingredientName: string | undefined, unitValue: number | string, unit: string = 'g') {
  if(!ingredientName) {
    return '??';
  }

  return ingredientName + ' ' + unitValue + unit;
}