import ListItemButton from "@mui/material/ListItemButton";
import { Recipe } from "../../../model/Recipe";
import { ListItem, ListItemText } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function RecipeLine(props: { recipe: Recipe, onApplyRecipe?: (recipe: Recipe) => void }) {

    const navigate = useNavigate();

    function handleOpenRecipe(recipeId?: number): any {
        navigate('/recipe/' + recipeId, { state: props.recipe });
    }
    
    function handleApplyRecipe(recipe: Recipe): any {
        if (props.onApplyRecipe) {
            props.onApplyRecipe(recipe);
        }
    }

    const applyArrow = props.onApplyRecipe ? <ArrowBackIcon
        onClick={() => handleApplyRecipe(props.recipe)}
    /> : <></>;

    return (
        <ListItem>
            {applyArrow}
            <ListItemButton key={props.recipe.name} component="a">
                <ListItemText primary={props.recipe.name} onClick={() => handleOpenRecipe(props.recipe.id)} />
            </ListItemButton>
        </ListItem>
    )
}