import { Ingredient } from "../../../model/Ingredient";
import { MealIngredient } from "../../../model/MealIngredient";
import { PresentableMealIngredient } from "./PresentableMealIngredient";

export function ingredientToPresentableMealIngredient(ingredient: Ingredient): PresentableMealIngredient {
    return { ...ingredient } as PresentableMealIngredient;
}

export function presentableMealIngredientToMealIngredient(presentableMealIngredient: PresentableMealIngredient): MealIngredient {
    return {
        ingredientId: presentableMealIngredient.id,
        unitValue: presentableMealIngredient.unitValue
    }
}