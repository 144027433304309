import axios, { AxiosResponse } from "axios";
import { Day as MealsOfDay } from "../model/Day"
import { formatToDateOnly } from "../Common";
import { AuthContextProps } from "react-oidc-context";
import { Ingredient } from "../model/Ingredient";
import { Recipe } from "../model/Recipe";
import { Category } from "../model/Category";

export class ApiService {

    private static baseUrl = 'https://meal-calculator.siof.pl/api/';

    static async getIngredients() {
        return axios.get<Ingredient[]>(this.baseUrl + "ingredients");

    }

    static async getIngredient(ingredientId: number): Promise<AxiosResponse<Ingredient, any>> {
        return axios.get<Ingredient>(this.baseUrl + "ingredients/" + ingredientId);

    }

    static async removeIngredient(ingredientId: number) {
        return axios.delete<any>(this.baseUrl + "ingredients/" + ingredientId);

    }

    static async updateIngredient(ingredient: Ingredient, auth: AuthContextProps) {
        let config = {
            headers: {
                Authorization: 'Bearer ' + auth.user?.access_token,
            }
        }

        axios.put<Ingredient>(this.baseUrl + `ingredients/${ingredient.id}`, ingredient, config);
    }

    static async addIngredient(ingredient: Ingredient, auth: AuthContextProps) {
        let config = {
            headers: {
                Authorization: 'Bearer ' + auth.user?.access_token,
            }
        }

        axios.post<Ingredient>(this.baseUrl + `ingredients`, [ingredient], config);
    }

    static async getMealsOfDays(dateFrom: Date, dateTo: Date, auth: AuthContextProps): Promise<AxiosResponse<MealsOfDay[]>> {
        let config = {
            headers: {
                Authorization: 'Bearer ' + auth.user?.access_token,
            }
        }

        return axios.get<MealsOfDay[]>(this.baseUrl + `days/meals?fromDate=${formatToDateOnly(dateFrom)}&toDate=${formatToDateOnly(dateTo)}`, config);
    }

    static async getRecipes(auth: AuthContextProps, page: number) {
        let config = {
            headers: {
                Authorization: 'Bearer ' + auth.user?.access_token,
            }
        }

        return axios.get<any[]>(this.baseUrl + `recipes?page=${page}&results=100`, config);
    }

    static async getRecipesOfCategory(auth: AuthContextProps, categoryId: number, page: number): Promise<AxiosResponse<Recipe[]>> {
        return axios.get<Recipe[]>(this.baseUrl + `recipes/category/${categoryId}?page=${page}&results=100`, ApiService.config(auth));
    }

    static async getRecipe(id: number, auth: AuthContextProps) {
        let config = {
            headers: {
                Authorization: 'Bearer ' + auth.user?.access_token,
            }
        }

        return axios.get<Recipe>(this.baseUrl + `recipes/` + id, config);
    }

    static async addRecipe(recipe: Recipe, auth: AuthContextProps) {
        let config = {
            headers: {
                Authorization: 'Bearer ' + auth.user?.access_token,
            }
        }

        axios.post<Recipe>(this.baseUrl + `recipes`, recipe, config);
    }

    static async updateRecipe(recipe: Recipe, auth: AuthContextProps) {
        let config = {
            headers: {
                Authorization: 'Bearer ' + auth.user?.access_token,
            }
        }

        axios.put<Recipe>(this.baseUrl + `recipes/` + recipe.id, recipe, config);
    }

    static async updateMealsOfDay(body: MealsOfDay, auth: AuthContextProps) {
        let config = {
            headers: {
                Authorization: 'Bearer ' + auth.user?.access_token,
            }
        }
        const date = body.date;
        axios.put<MealsOfDay>("https://meal-calculator.siof.pl/api/days/meals/date/" + date, body, config)
    }

    static async deleteMeals(id: number, auth: AuthContextProps) {
        let config = {
            headers: {
                Authorization: 'Bearer ' + auth.user?.access_token,
            }
        }
        axios.delete<MealsOfDay>("https://meal-calculator.siof.pl/api/days/meals/" + id, config)
    }

    static async createMealsOfDay(body: any, auth: AuthContextProps) {
        let config = {
            headers: {
                Authorization: 'Bearer ' + auth.user?.access_token,
            }
        }

        axios.post<MealsOfDay>("https://meal-calculator.siof.pl/api/days/meals", body, config)
    }

    static async getCategories(auth: AuthContextProps): Promise<AxiosResponse<Category[]>> {
        let config = {
            headers: {
                Authorization: 'Bearer ' + auth.user?.access_token,
            }
        }

        return axios.get<Category[]>(this.baseUrl + `categories`, config);
    }

    static async addCategory(name: string, auth: AuthContextProps) {
        let config = {
            headers: {
                Authorization: 'Bearer ' + auth.user?.access_token,
            }
        }

        axios.post<string>(this.baseUrl + `categories`, { 'name': name }, config);
    }

    static config(auth: AuthContextProps) {
        return {
            headers: {
                Authorization: 'Bearer ' + auth.user?.access_token,
            }
        }
    }
}
