import { Autocomplete, Button, Paper, Snackbar, SnackbarCloseReason, Stack, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { Ingredient } from "../../model/Ingredient";
import { ApiService } from "../../services/ApiService";
import IngredientLine from "../meal_planner/IngredientLine";
import DeleteIcon from '@mui/icons-material/Delete';
import { Recipe } from "../../model/Recipe";
import { RecipeIngredient } from "../../model/RecipeIngredient";
import { Category } from "../../model/Category";

export default function AddRecipe() {

    const auth = useAuth();

    const [name, setName] = useState<string>();
    const [description, setDescription] = useState<string>();
    const [steps, setSteps] = useState<string[]>([""]);
    const [ingredients, setIngredients] = useState<Ingredient[]>([{} as Ingredient]);
    const [availableIngredients, setAvailableIngredients] = useState<Ingredient[]>([{} as Ingredient]);
    const [availableIngredientsKeys, setAvailableIngredientsKeys] = useState<string[]>([]);
    const [availableCategories, setAvailableCategories] = useState<Category[]>([]);
    const [category, setCategory] = useState<Category | undefined>();

    const [snackBarOpen, setSnackBarOpen] = useState<boolean>(false);
    const [snackBarMessage, setSnackBarMessage] = useState<string>("");

    useEffect(() => {
        ApiService.getIngredients().then((response) => {
            let newIngredientKeys = [""];

            response.data.forEach(i => {
                newIngredientKeys.push(i.name)
            })

            setAvailableIngredientsKeys(newIngredientKeys)
            setAvailableIngredients(response.data)
        })
    }, [])

    useEffect(() => {
        ApiService.getCategories(auth)
            .then((response) => {
                setAvailableCategories(response.data);
            })
            .catch(() => {
            })
    }, [])

    const handleCloseSnackbar = (
        event: React.SyntheticEvent | Event,
        reason?: SnackbarCloseReason,
    ) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackBarOpen(false);
    };

    function save() {
        const recipeIngredients = ingredients.map(i => {
            return {
                ingredientId: i.id,
                unitValue: i.unitValue
            } as RecipeIngredient;
        });

        ApiService.addRecipe({
            name: name,
            author: "author",
            description: description,
            categories: [category],
            steps: steps,
            meals: 1,
            ingredients: recipeIngredients

        } as Recipe, auth)
            .then(() => {
                setSnackBarMessage("Recipe added");
                setSnackBarOpen(true);
            })
            .catch(() => {
                setSnackBarMessage("Recipe creation error");
                setSnackBarOpen(true);
            });
    }

    function addStep() {
        const currentSteps = steps;
        setSteps([...currentSteps, ""]);
    }

    function addIngredient() {
        const currentIngredients = ingredients;
        setIngredients([...currentIngredients, {} as Ingredient]);
    }

    function changeSelectedIngredient(index: number, newValue: string) {
        const currentIngredients = ingredients;
        currentIngredients[index] = availableIngredients
            .filter(ingredient => {
                return ingredient.name == newValue
            })[0];

        setIngredients([...currentIngredients])
    }

    function removeIngredient(index: number) {
        const currentIngredients = ingredients;

        setIngredients([...currentIngredients]);
    }

    function removeStep(index: number) {
        let currentSteps = steps;
        currentSteps.splice(index, 1);
        setSteps([...currentSteps]);
    }

    function changeSelectedIngredientUnitValue(index: number, newValue: number) {
        const currentIngredients = ingredients;

        currentIngredients[index].unitValue = newValue;

        setIngredients([...currentIngredients]);
    }

    function onChangeSelectedCategory(newValue: string) {
        const newCategory = availableCategories.find((availableCategory => availableCategory.name == newValue));
        setCategory(newCategory);
    }

    return (
        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
            <Stack spacing={2}>
                Add recipe
                <TextField
                    required
                    id="name"
                    name="name"
                    label="Name"
                    fullWidth
                    variant="standard"
                    onChange={(event: any) => {
                        setName(event.target.value)
                    }}
                    value={name}
                />
                <TextField
                    required
                    id="description"
                    name="description"
                    label="description"
                    fullWidth
                    variant="standard"
                    onChange={(event: any) => {
                        setDescription(event.target.value)
                    }}
                    value={description}
                />
                <Autocomplete
                    onChange={(event: any, newValue: string | null) => {
                        if (newValue) {
                            onChangeSelectedCategory(newValue);
                        }
                    }
                    }
                    disablePortal
                    data-testid={"category-combo-box"}
                    value={category?.name}
                    options={availableCategories.map(category => category.name)}
                    // sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="Category" />}
                />
                {steps.map((step, index) => {
                    return <>
                        <TextField
                            required
                            id={"recipe-step-" + index}
                            name="step"
                            label={"step " + (index + 1)}
                            fullWidth
                            variant="standard"
                            onChange={(event: any) => {
                                let currentSteps = steps;
                                currentSteps[index] = event.target.value;
                                setSteps([...currentSteps]);
                                // steps[index] = event.target.value;
                            }}
                            value={steps[index]}
                        />
                        <DeleteIcon onClick={() => { removeStep(index) }} sx={{ mt: 1.5 }} />
                    </>
                })}

                <Button variant="contained" sx={{ mt: 3, ml: 1 }} onClick={addStep}>Add step</Button>

                {ingredients.map((i, index) => {
                    return <IngredientLine
                        key={i.id}
                        availableIngredientsKeys={availableIngredientsKeys}
                        index={index}
                        ingredient={i}
                        onChangeSelectedIngredients={changeSelectedIngredient}
                        onChangeSelectedIngredientsUnitValue={changeSelectedIngredientUnitValue}
                        onRemoveIngredient={removeIngredient}
                    />
                })}
                <Button variant="contained" sx={{ mt: 3, ml: 1 }} onClick={addIngredient}>Add ingredient</Button>

                <Button variant="contained" sx={{ mt: 3, ml: 1 }} onClick={save}>Add recipe</Button>
            </Stack>
            <Snackbar
                open={snackBarOpen}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
                message={snackBarMessage}
            />
        </Paper>
    )
}