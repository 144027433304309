import * as React from 'react';
import { Stepper as MuiStepper, Step, StepLabel } from '@mui/material';
import { useEffect, useState } from 'react';

export default function Stepper(props: { activeStep: number, steps: string[] }) {

    const [steps, setSteps] = useState<string[]>([]);
    
    useEffect(() => {
        setSteps(props.steps);
    },[props.steps]);
    

    return (
        <MuiStepper activeStep={props.activeStep}
            sx={{ pt: 3, pb: 5 }}
        >
            {steps.map((label) => (
                <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                </Step>
            ))}
        </MuiStepper>
    )
}