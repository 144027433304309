import * as React from 'react';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import MealDetails from './meal_planner/meal_details/MealDetails';
import DailySummary from './meal_planner/DailySummary';
import { Meal } from '../model/Meal';
import { ReactNode, useEffect, useState } from 'react';
import axios from 'axios';
import { Day, Day as MealsOfDay } from "../model/Day"
import { useAuth } from 'react-oidc-context';
import { ApiService } from '../services/ApiService';
import { Recipe } from '../model/Recipe';
import StepButtons from './meal_planner/meals_builder/StepButtons';
import Stepper from './meal_planner/meals_builder/Stepper';
import { Snackbar, SnackbarCloseReason } from '@mui/material';
import { useTranslation } from 'react-i18next';


interface CalculationStepperProps {
  date: Date,
  recipeToBeApplied?: Recipe | undefined,
  setRecipeToBeApplied: (recipe?: Recipe) => void
}

export default function CalculationStepper({ date, recipeToBeApplied, setRecipeToBeApplied }: CalculationStepperProps) {
  const { t } = useTranslation();

  let lastStep = t('Save');
  let initialSteps = [
    'Meal 1',
    lastStep
  ];
  const [steps, setSteps] = useState(initialSteps);
  const [activeStep, setActiveStep] = useState(0);
  const [meals, setMeals] = useState<Map<number, Meal>>(new Map<number, Meal>([[activeStep, newEmptyMeal(activeStep)]]));
  const auth = useAuth();
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [deletedDay, setDeletedDay] = useState(false);
  const [dayId, setDayId] = useState<number | undefined>();

  const [snackBarOpen, setSnackBarOpen] = useState<boolean>(false);
  const [snackBarMessage, setSnackBarMessage] = useState<string>("");

  useEffect(() => {
    ApiService.getMealsOfDays(date, date, auth)
      .then((response) => {
        if (response.status === 200 && response.data) {
          const day: MealsOfDay = response.data[0];

          let selectedDayMeals: Meal[] = [];

          if (response.data.length > 0) {
            setShouldUpdate(true);
            setDayId(day.id)
            selectedDayMeals = response.data[0]?.meals;
          } else {
            setShouldUpdate(false);
            setDayId(undefined);
          }

          resetSteps(selectedDayMeals.map(meal => meal.name));

          let newMealsWithOrderIndexes = mapMealsToMealsOrderIndexed(selectedDayMeals);

          setMeals(newMealsWithOrderIndexes);
          setActiveStep(0);
        }
        setActiveStep(0);
      })
      .catch(reason => {
        console.log('getMealsOfDay ERROR', reason);
      });
    setDeletedDay(false);
  }, [date, deletedDay]);

  useEffect(() => {
    applyIngredientsFromRecipe();
  }, [recipeToBeApplied])

  function mapMealsToMealsOrderIndexed(meals: Meal[]): Map<number, Meal> {
    let result: Map<number, Meal>;

    result = new Map(meals.map(meal => {
      if (!meal.tmpId && meal.id) {
        meal.tmpId = meal.id;
      }
      return [meal.order, meal];
    }))

    return result;
  }

  function resetSteps(newSteps: string[] | null) {
    if (!newSteps || newSteps.length == 0) {
      newSteps = initialSteps;
    } else {
      newSteps.push(lastStep);
    }
    setSteps(Array.of(...newSteps));
  }

  function deleteMeal(mealId: number | undefined) {

    if (dayId) {
      ApiService.deleteMeals(dayId, auth).then(() => {
        setSnackBarMessage("Meal deleted");
        setDeletedDay(true);
      }).catch(() => {
        setSnackBarMessage("Meal deletion error");
      }).finally(() => {
        setSnackBarOpen(true);
      });
    }
  }

  function updateSelectedMeal(meal: Meal) {
    let newMeals = meals;
    const index = meal.order;
    newMeals?.set(index, meal);
    setMeals(new Map<number, Meal>(meals));
  }

  function getStepContent(step: number) {
    switch (step) {
      case steps.length - 1:
        return <DailySummary meals={[]} />;
      default:
        return <MealDetails
          meal={(meals?.get(step)) ? meals?.get(step) as Meal : newEmptyMeal(step)}
          date={date}
          onMealChange={updateSelectedMeal}
          onDeleteMeal={deleteMeal}
        />;
    }
  }

  function newEmptyMeal(step: number): Meal {
    return {
      tmpId: step,
      name: steps[step]
    } as Meal;
  }

  function handleNewMeal(): void {
    steps.splice(steps.length - 1, 0, "Meal " + (activeStep + 2));
    setSteps(Array.of(...steps));
    setActiveStep(activeStep + 1);
  }

  function applyIngredientsFromRecipe(): void {
    let meal = meals?.get(activeStep);
    if (meal && recipeToBeApplied) {
      meal.recipeId = recipeToBeApplied.id;
      meal.ingredients = recipeToBeApplied.ingredients;
      updateSelectedMeal(meal);
      // setRecipeToBeApplied(undefined);
    }
  }

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const saveMeals = () => {
    const body: MealsOfDay = {
      'date': date.toISOString().split('T')[0],
      'meals': Array.from(meals?.values() ?? [])
    };

    if (shouldUpdate) {
      ApiService.updateMealsOfDay(body, auth)
        .then((response) => {
          setSnackBarMessage("Meals updated");
          setSnackBarOpen(true);
        })
        .catch((reason) => {
          setSnackBarMessage("Meals saving error " + reason);
          setSnackBarOpen(true);
        });
    } else {
      ApiService.createMealsOfDay(body, auth)
        .then((response) => {
          setSnackBarMessage("Meals saved");
          setSnackBarOpen(true);
        })
        .catch(() => {
          setSnackBarMessage("Meals saving error");
          setSnackBarOpen(true);
        });
    }
  };

  const handleCloseSnackbar = (
    event: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackBarOpen(false);
  };

  return (
    <Container component="main" maxWidth={false} sx={{ mb: 4 }}>
      <Paper variant="outlined"
        sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
      >
        <br />
        <Stepper activeStep={activeStep} steps={steps} />
        {activeStep === steps.length ? (
          <>
            <Typography variant="h5" gutterBottom>
            </Typography>
            <Typography variant="subtitle1">
              Saved.
            </Typography>
          </>
        ) : (
          <>
            {getStepContent(activeStep)}
            <StepButtons activeStep={activeStep} steps={steps} handleNewMeal={handleNewMeal} handleBack={handleBack} handleNext={handleNext} saveMeals={saveMeals} />
          </>
        )}
        <Snackbar
          open={snackBarOpen}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          message={snackBarMessage}
        />
      </Paper>
    </Container>
  );
}
