import { Button, CircularProgress, List, ListItemButton, ListItemText, Paper } from "@mui/material";
import { useAuth } from "react-oidc-context";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Category } from "../../model/Category";
import { ApiService } from "../../services/ApiService";

export default function CategoryBrowser() {

    const auth = useAuth();
    const navigate = useNavigate();
    const [categories, setCategories] = useState([] as Category[]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        ApiService.getCategories(auth)
            .then((response) => {
                setCategories(response.data);
            })
            .catch(() => {
            })
            .finally(() => {
                setLoading(false);
            })
    }, [])

    function handleOpenRecipeCreation(): any {
        navigate('/add_category');
    }

    const progress = loading ? <CircularProgress sx={{ my: { md: 10 } }} /> : <></>;

    return (
        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
            {progress}
            {categories.map(category => {
                return (
                    <List>
                        <ListItemButton key={category.name} component="a">
                            <ListItemText primary={category.name} onClick={() => handleOpenRecipeCreation()} />
                        </ListItemButton>
                        {/* <RecipeLine recipe={recipe} key={recipe.name} onApplyRecipe={props.applyRecipe} /> */}
                    </List>
                )
            })}
            <Button variant="contained" sx={{ mt: 3, ml: 1 }} onClick={handleOpenRecipeCreation}>Add</Button>
        </Paper>
    );
}