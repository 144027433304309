import { useEffect, useState } from "react";
import { ApiService } from "../../services/ApiService";
import { useAuth } from "react-oidc-context";
import { MealIngredient } from "../../model/MealIngredient";
import { SetWithContentEquality } from "./SetWithContentEquality";
import { CircularProgress, List, ListItem, Paper, TextField, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useTranslation } from "react-i18next";

export default function ShoppingList() {

    interface ShoppingIngredient {
        id: number,
        name: string;
        unitValue: number;
        recipeId: number;
        recipeName: number;
    }

    const today: Date = new Date();
    const todayPlus7days: Date = new Date();
    todayPlus7days.setDate(today.getDate() + 7);

    const [loading, setLoading] = useState(false);
    const [dateFrom, setDateFrom] = useState<Date | null>(today);
    const [dateTo, setDateTo] = useState<Date | null>(todayPlus7days);
    const [mealIngredients, setMealIngredients] = useState<MealIngredient[]>([]);
    const [shoppingIngredients, setShoppingIngredients] = useState<ShoppingIngredient[]>([]);

    const auth = useAuth();
    const { t } = useTranslation();

    useEffect(() => {
        setLoading(true);
        ApiService.getMealsOfDays(dateFrom ? dateFrom : new Date(), dateTo ? dateTo : new Date(), auth)
            .then((response) => {
                const mealIngredientsNew = new SetWithContentEquality<MealIngredient>(ingredient => ingredient.ingredientId as any as string);

                response.data.forEach(day => {
                    day.meals.map(meal => {
                        meal.ingredients.map(ingredient => {
                            accumulateMealIngredient(mealIngredientsNew, ingredient);
                        });
                    });
                });
                setMealIngredients(mealIngredientsNew.values());
            })
            .finally(() => {
                setLoading(false);
            })

    }, [dateFrom, dateTo]);

    useEffect(() => {
        setLoading(true);
        const shoppingIngredients: ShoppingIngredient[] = [];

        const promises = mealIngredients.map(mealIngredient => {
            return ApiService.getIngredient(mealIngredient.ingredientId)
                .then((response) => {
                    const shoppingIngredient = {} as ShoppingIngredient;
                    shoppingIngredient.id = response.data.id;
                    shoppingIngredient.unitValue = mealIngredient.unitValue;
                    shoppingIngredient.name = response.data.name;

                    shoppingIngredients.push(shoppingIngredient);
                })
        });
        Promise.all(promises)
            .then(() => {
                setShoppingIngredients(shoppingIngredients);
            })
            .finally(() => {
                setLoading(false);
            })

    }, [mealIngredients]);

    function accumulateMealIngredient(mealIngredients: SetWithContentEquality<MealIngredient>, mealIngredient: MealIngredient) {
        if (mealIngredients.has(mealIngredient)) {
            let alreadyPresentIngredient = mealIngredients.values().find(ingr => ingr.ingredientId == mealIngredient.ingredientId);
            if (alreadyPresentIngredient) {
                alreadyPresentIngredient.unitValue = alreadyPresentIngredient?.unitValue + mealIngredient.unitValue;
            }
        } else {
            mealIngredients.add(mealIngredient);
        }
    }

    const progress = loading ? <CircularProgress sx={{ my: { md: 10 } }} /> : <></>;

    return (
        <>
            <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                <Typography variant="h6" gutterBottom mb={3}>
                    {t("Shopping list")}
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        label="Date from"
                        value={dateFrom}
                        onChange={(newValue) => {
                            setDateFrom(newValue);
                        }}

                    />
                    <DatePicker
                        label="Date to"
                        value={dateTo}
                        onChange={(newValue) => {
                            setDateTo(newValue);
                        }}

                    />
                </LocalizationProvider>
                <List>
                    {progress}
                    {shoppingIngredients.map((ingredient, index) => {
                        return (
                            <ListItem>{index + 1}. {t(ingredient.name)} {ingredient.unitValue}g</ListItem>
                        )
                    })}
                </List>
            </Paper>
        </>
    )
}