import { Button, Paper, Snackbar, SnackbarCloseReason, Stack, TextField } from "@mui/material";
import { useState } from "react";
import { useAuth } from "react-oidc-context";
import { ApiService } from "../../services/ApiService";

export default function AddCategory() {

    const auth = useAuth();

    const [name, setName] = useState<string>();

    const [snackBarOpen, setSnackBarOpen] = useState<boolean>(false);
    const [snackBarMessage, setSnackBarMessage] = useState<string>("");

    const handleCloseSnackbar = (
        event: React.SyntheticEvent | Event,
        reason?: SnackbarCloseReason,
    ) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackBarOpen(false);
    };

    function save() {
        if (name) {
            ApiService.addCategory(name, auth)
                .then(() => {
                    setSnackBarMessage("Category added");
                    setSnackBarOpen(true);
                })
                .catch(() => {
                    setSnackBarMessage("Category creation error");
                    setSnackBarOpen(true);
                });
        }
    }

    return (
        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
            <Stack spacing={2}>
                Add category
                <TextField
                    required
                    id="name"
                    name="name"
                    label="Name"
                    fullWidth
                    variant="standard"
                    onChange={(event: any) => {
                        setName(event.target.value)
                    }}
                    value={name}
                />

                <Button variant="contained" sx={{ mt: 3, ml: 1 }} onClick={save}>Add category</Button>
            </Stack>
            <Snackbar
                open={snackBarOpen}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
                message={snackBarMessage}
            />
        </Paper>
    )
}