import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import MealSummary from '../MealSummary';
import IngredientLine from '../IngredientLine';
import { Meal } from '../../../model/Meal';
import { MealIngredient } from "../../../model/MealIngredient";
import PlusIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';
import { ApiService } from '../../../services/ApiService';
import DeleteIcon from '@mui/icons-material/Delete';
import { Recipe } from '../../../model/Recipe';
import { useAuth } from 'react-oidc-context';
import { PresentableMealIngredient } from './PresentableMealIngredient';
import { ingredientToPresentableMealIngredient, presentableMealIngredientToMealIngredient } from './mapper';
import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function MealDetails(props: { meal: Meal, date: Date, onMealChange: (meal: Meal) => void, onDeleteMeal: (mealId: number | undefined) => void }) {

  const tmpId = props.meal?.tmpId;
  const auth = useAuth();
  const {t} = useTranslation();

  const [availableIngredientsByName, setAvailableIngredientsByName] = useState(new Map([]) as Map<string, PresentableMealIngredient>);
  const [selectedIngredients, setSelectedIngredients] = useState<PresentableMealIngredient[]>([]);
  const [mealName, setMealName] = useState(props.meal?.name);
  const [recipe, setRecipe] = useState<Recipe | undefined>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    ApiService.getIngredients()
      .then((response) => {
        let newAvailableIngredients: Map<string, PresentableMealIngredient> = new Map([]);

        const presentableIngredients = response.data.map(ingredient => {
          return ingredientToPresentableMealIngredient(ingredient);
        });

        presentableIngredients.forEach(ingr => {
          newAvailableIngredients.set(ingr.name, ingr);
        })
        setAvailableIngredientsByName(newAvailableIngredients);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const setAndNotifySelectedIngredients = (selectedIngredients: PresentableMealIngredient[]) => {
    setSelectedIngredients(selectedIngredients);

    props.onMealChange({
      tmpId: tmpId,
      order: props.meal?.order != undefined ? props.meal.order : tmpId,
      name: mealName,
      recipeId: props.meal.recipeId,
      ingredients: selectedIngredients.map(selIngr => presentableMealIngredientToMealIngredient(selIngr))
    });
  }

  useEffect(() => {
    if (availableIngredientsByName.size > 0) {
      if (props.meal.ingredients) {
        const presentableMealIngredientsPromise = props.meal.ingredients.filter(i =>
          i !== null && i !== undefined && i.ingredientId !== undefined
        ).map(mealIngredient => {
          return mealIngredientToPresentableMealIngredient(mealIngredient)
        });

        setLoading(true);
        Promise.all(presentableMealIngredientsPromise)
          .then(presentableMealIngredients => {
            setAndNotifySelectedIngredients(presentableMealIngredients);
          })
          .finally(() => {
            setLoading(false);
          })

      }
      else {
        setAndNotifySelectedIngredients([initialIngredient()]);
      }
    }
  }, [availableIngredientsByName, props.meal.tmpId, props.meal.recipeId, props.date]);

  useEffect(() => {
    const recipeId = props.meal.recipeId;

    if (recipeId) {
      ApiService.getRecipe(recipeId, auth).then((response) => {
        setRecipe(response.data);
      });
    }
  }, [props.meal.recipeId]);

  async function mealIngredientToPresentableMealIngredient(mealIngredient: MealIngredient): Promise<PresentableMealIngredient> {
    const ingredient = (await ApiService.getIngredient(mealIngredient.ingredientId)).data;

    return { ...ingredient, unitValue: mealIngredient.unitValue } as PresentableMealIngredient;
  }

  function initialIngredient(): PresentableMealIngredient {
    const initialIngredient = availableIngredientsByName.get('couscous') as PresentableMealIngredient;
    initialIngredient.unitValue = 100;

    return initialIngredient;
  }

  function addProduct() {
    let newIngredients = [...selectedIngredients];
    newIngredients.push(initialIngredient());
    setAndNotifySelectedIngredients(newIngredients);
  }

  function changeSelectedIngredientType(index: number, newValue: string) {
    let newIngr = availableIngredientsByName.get(newValue);
    if (newIngr) {
      let newIngredients = selectedIngredients;
      newIngredients[index] = newIngr;
      setAndNotifySelectedIngredients([...newIngredients]);
    }
  }

  function changeSelectedIngredientUnitValue(index: number, newValue: number) {
    let newIngredients = selectedIngredients;
    newIngredients[index].unitValue = newValue;
    setAndNotifySelectedIngredients([...newIngredients]);
  }

  function removeInredient(index: number) {
    let newIngredients = selectedIngredients;
    newIngredients.splice(index, 1);
    setAndNotifySelectedIngredients([...newIngredients]);
  }

  const progress = loading ? <CircularProgress sx={{ my: { md: 10 } }} /> : <></>;

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        {props.meal.recipeId ? <a href={"/recipe/" + props.meal.recipeId}>{recipe?.name}</a> : ''}
      </Typography>
      <Typography variant="h6" gutterBottom>
        {t("Ingredients")}
      </Typography>
      <br />
      {progress}
      <Grid container spacing={2}>
        {selectedIngredients.map((i, index) => {
          return <IngredientLine
            key={i.id}
            availableIngredientsKeys={Array.from(availableIngredientsByName.keys())}
            index={index}
            ingredient={i}
            onChangeSelectedIngredients={changeSelectedIngredientType}
            onChangeSelectedIngredientsUnitValue={changeSelectedIngredientUnitValue}
            onRemoveIngredient={removeInredient}
          />
        })}
        <Grid item xs={12}>
          {/*           <Tooltip title={'Add new ingredient to your meal'}> */}
          <PlusIcon onClick={() => addProduct()}
            sx={{ mt: 0 }} />
          {/*           </Tooltip> */}
          <br />
          <br />
        </Grid>
        <Grid item xs={12}>
          <MealSummary selectedIngredients={selectedIngredients} />
        </Grid>
        <Grid item xs={12}>
          <DeleteIcon onClick={() => props.onDeleteMeal(props.meal.id)} sx={{ mt: 1.5 }} />
        </Grid>
      </Grid>

    </React.Fragment >
  );
}
