import Grid from "@mui/material/Grid";


export default function OneColumnPage(props: any) {
    return (
        <Grid container spacing={2}>
            <Grid item md={1} xs={1}></Grid>
            <Grid item md={10} xs={10}>
                {props.children}
            </Grid>
            <Grid item md={1} xs={1}></Grid>
        </Grid>
    )
}